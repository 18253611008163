/** @jsx jsx */
import { css, jsx } from "@emotion/react";
import { useTheme } from "../components/ThemeProvider";
import * as React from "react";
import { Helmet } from "react-helmet";
import { betaData as data } from "../data/beta-data";
import {
  Hero,
  CardLayout as OurPromise,
  MissionCTA as CTA,
} from "../components/layoutElements";
import { IPageSection } from "../data/data-types";

function Beta() {
  const theme = useTheme();
  const heroData = data.hero as IPageSection;
  const ourPromiseData = data.ourPromise as IPageSection;
  const ctaData = data.joinBeta as IPageSection;

  return (
    <>
      <Helmet>
        <title>{data.title}</title>
      </Helmet>
      <main
        css={css({
          backgroundColor: theme.colors.alabaster,
        })}
      >
        <Hero
          data={heroData}
          imageWidth="80%"
          smCol={9}
          positionTextBodyAboveFold
        />
        <CTA data={ctaData} />
        <OurPromise data={ourPromiseData} colLg={6} />
      </main>
    </>
  );
}

export default Beta;
